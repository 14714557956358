<template>
    <div class="lightbox" v-if="which">
        <!--Light Box-->
        <!--First Run-->
        <div class="card firstrun" v-if="isIntro">
            <div @click="onCloseClick" class="close">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <img :src="branding.Logo" />
            <h1>Thank You For Adding {{ branding.ProductName }}</h1>
            <p>
                Search easiest route to wherever you are trying to go and much
                more! Never lose your way again with {{ branding.ProductName }}!
            </p>
            <div class="btnWrapper">
                <button @click="onCloseClick()" class="ok left">
                    Search Maps
                </button>
                <button @click="pickLearnQuickTips()" class="ok left">
                    Quick Tips
                </button>
            </div>
        </div>
        <!-- End of First Run-->

        <!--Tips-->
        <div class="card firstrun" v-else-if="isTutorial">
            <div class="close" @click="onCloseClick">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <img :src="branding.Logo" />
            <h1>Getting the most out of {{ branding.ProductName }}</h1>
            <img :src="branding.TypeGIF" class="type" />
            <ol>
                <li>Go to the address bar and type <strong>"map"</strong></li>
                <li>
                    After <strong>"map"</strong> hit spacebar then the location
                    you would like to search.
                    <div class="sb">
                        <strong>Example:</strong><br />
                        <i class="fa fa-circle"></i> Single location Search:
                        <strong>"San Francisco"</strong> <br />
                        <i class="fa fa-circle"></i> Direction Search:
                        <strong>"San Francisco to Los Angeles"</strong>
                    </div>
                </li>
                <li>And then hit enter to get directions</li>
            </ol>
            <div class="btnWrapper">
                <button class="ok left" v-show="false">Back</button>
                <button class="ok left" @click="onCloseClick()">OK</button>
            </div>
        </div>
        <!-- End Tips-->
    </div>
</template>

<script>
import queryString from "query-string";
import { MAP_SEARCH_API, openUrl } from "../urls";
import { getBranding } from "../branding";

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

export default {
    name: "first-run",
    props: ["which", "onClose", "onNext"],
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            query: qs.q || "",
            engine: qs.engine || MAP_SEARCH_API.google,
            branding: getBranding(),
        };
    },
    methods: {
        canShow(name) {
            return this.which && this.which.endsWith(name);
        },
        pickLearnQuickTips() {
            switch (this.which) {
                case "intro":
                    this.onNext("it");
            }
        },

        async onCloseClick() {
            if (this.query.length > 0) {
                openUrl(this.engine + this.query, false);
            } else {
                this.onClose && this.onClose(...arguments);
            }
        },
    },
    computed: {
        isTutorial() {
            return this.canShow("tutorial");
        },
        isIntro() {
            return this.canShow("intro");
        },
    },
};
</script>
