<template>
    <div>
        <!-- Bookmark Popup  -->
        <first-run
            :which="whichPop"
            :onClose="onCloseHelp"
            :onNext="onNextPop"
        />
        <!-- First Run Popup -->
        <!----search module----->
        <div class="searchModule">
            <div class="sLogo"><img :src="branding.Logo" /></div>
            <div class="search web">
                <input
                    ref="mapQuery"
                    v-model="query"
                    @return="onMapReturn()"
                    @keydown.enter="onMapReturn()"
                    placeholder="Search Location"
                />
                <div class="s-icon"><i class="fa fa-search"></i></div>
                <div class="error" v-if="queryError">
                    This field is required, Please enter
                </div>
            </div>
            <div class="sBtnContainer">
                <button class="btn-search mapSearch" @click="onMapReturn()">
                    Map Search
                </button>
                <button
                    class="btn-search webSearch"
                    id="websrch-btn"
                    @click="startWebSearch()"
                >
                    Web Search
                </button>
            </div>
        </div>
        <!----end oksearch module----->
        <!--footer-->
        <footer>
            <div class="serp-footer">
                <div class="logoFooter">
                    <img :src="branding.GrayLogo" /><span
                        ><i class="fa fa-copyright" aria-hidden="true"></i>
                        <span id="brandDetails"> {{ branding.Trademark }}</span>
                    </span>
                </div>
                <ul>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/terms-of-service/`
                            "
                            >Terms of Service
                        </a>
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/privacy-policy/`
                            "
                            >Privacy</a
                        >
                    </li>
                    <li>
                        <a :href="`https://${branding.RootDomain}/contact-us/`"
                            >Contact Us</a
                        >
                    </li>
                </ul>
            </div>
        </footer>
        <!--end of footer-->
    </div>
</template>
<script>
import bp from "babel-polyfill";
import queryString from "query-string";
import "whatwg-fetch";
import Humanize from "humanize-plus";
import { getGuid } from "./storage";
import { WEB_SEARCH_URL, MAP_SEARCH_API, openUrl } from "./urls";
import FirstRun from "./components/FirstRun.vue";
import { getBranding } from "./branding";
import { documentReady } from "./common";
import { arrow } from "../newtab";

function validatePop(name) {
    switch (name) {
        case "intro":
        case "i":
            return "intro";
        case "t":
        case "tutorial":
            return "tutorial";
        case "it":
            return "intro-tutorial";
    }

    return false;
}

export default {
    name: "serp",
    components: {
        "first-run": FirstRun,
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            query: "",
            mapSearch: true,
            queryError: false,
            guid: qs.guid,
            whichPop: validatePop(qs.pop) || false,
            branding: getBranding(),
            engine: MAP_SEARCH_API[qs.engine] || MAP_SEARCH_API.google,
        };
    },
    mounted() {
        documentReady.then(async () => {
            arrow("newtab");
            this.$refs.mapQuery.focus();
            try {
                this.guid = await getGuid();
            } catch (e) {
                // Extension not available.
            }
        });
    },
    created() {},
    methods: {
        startWebSearch() {
            const searchParams = {
                k: this.query,
                guid: this.guid,
            };
            openUrl(
                `${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`,
                false
            );
        },
        validateMapSearch(withFocus) {
            const { query } = this;
            let errs = false;
            if (query == "") {
                errs = errs || {};
                errs.query = "This field is required, Please enter";
            }
            this.queryError = errs;
            if (withFocus) {
                if (errs.query) this.$refs.mapQuery.focus();
            }
            return errs === false;
        },
        async onMapReturn() {
            if (!this.validateMapSearch(true)) return;
            await this.doSearch(this.query);
        },
        async doSearch(term) {
            openUrl(this.engine + term, false);
        },
        toWebSearch() {
            this.mapSearch = false;
            this.$nextTick(() => this.$refs.webSearch.focus());
        },
        toMapSearch() {
            this.mapSearch = true;
            this.$nextTick(() => {
                this.$refs.mapQuery.focus();
            });
        },
        onNextPop(tag) {
            this.whichPop = validatePop(tag) || false;
        },
        onCloseHelp() {
            switch (this.whichPop) {
                case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
            }
            this.whichPop = false;
        },
    },
};
</script>

<style></style>
