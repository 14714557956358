import { sendMessageToExt } from "./storage";
import { isFirefox } from "./common";

const MAP_SEARCH_API = {
    google: "https://www.google.com/maps/search/?q=",
    bing: "https://www.bing.com/maps/?q=",
    yahoo: "https://maps.yahoo.com/b/?q=",
    here: "https://wego.here.com/search/",
};

const branding = require("./branding").getBranding();

const TOP_DOMAIN =
    (branding && branding.RootDomain) ||
    (function() {
        const parts = window.location.hostname.split(/\./);
        const domain =
            parts.length == 1
                ? window.location.hostname
                : parts.slice(-2).join(".");
        return (
            domain + (window.location.port ? `:${window.location.port}` : "")
        ).toLowerCase();
    })();

const WEB_SEARCH_URL = `https://services.${TOP_DOMAIN}/crx/search.php`;

function openUrl(toOpen, newTab = true) {
    if (!newTab) {
        return window.open(toOpen, "_self");
    }
    if (isFirefox) {
        return sendMessageToExt({ task: "openUrl", url: toOpen });
    }
    window.open(toOpen, "_blank");
}

export { MAP_SEARCH_API, WEB_SEARCH_URL, openUrl, TOP_DOMAIN };
