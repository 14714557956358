/**
 * Common functions and state management
 */
import Promise from "bluebird";

export const documentReady = new Promise(resolve => {
    if (document.readyState === "complete") {
        resolve(true);
        return;
    }
    document.addEventListener("DOMContentLoaded", function() {
        resolve(true);
    });
});

export const isFirefox = navigator.userAgent.includes("Firefox");
