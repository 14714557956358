const promptHTML = `
<div id="arrow-modal">
	<div id="arrow-modal-pointer" class="bounce">
		<img class="arrow-pointer" src="https://cdn.srchweb.info/assets/inline_install/green-up-arrow.png">
	</div>
	<div class="arrow-modal-top">
		<div id="arrow-modal-close">x</div>
		<img class="arrow-ico" src="https://cdn.srchweb.info/assets/inline_install/ok-green-square.png">
		<div class="arrow-direction">
			<p>Extension successfully installed!</p>
			<strong>Click Keep Changes</strong>
		</div>
	</div>
</div>
`;

const promptCSS = `
#arrow-modal {
	z-index: 100502;
	top: 320px;
	right:10px;
	width: 400px;
	box-shadow: 8px 12px 17px -5px rgba(0,0,0,0.58);
	border-radius: 5px;
	position: relative;
	font-family: Arial, Helvetica, sans-serif;
	position: fixed;
}
#arrow-modal-pointer{
	display:block;
	z-index: 100502;
	position: absolute;
	animation: bounce 1s infinite;
	top:-80px;
	right: 50px
}
.arrow-modal-top{
	background: #fff;
	border: 1px solid #a29797;
	position: absolute;
	display: block;
	position: relative;
	box-sizing: border-box;
	padding: 20px 20px 30px 140px;
	min-height: 140px;
	border-radius: 5px;
}
#arrow-modal-close{
	display:block;
	position: absolute;
	right: 8px;
	top: 0px;
	box-sizing: border-box;
	font-size: 18px;
	opacity: 0.5;
	cursor: pointer;
}
.arrow-ico{
	width: 100px;
	display: block;
	position: absolute;
	left: 20px;
	z-index: 199999;
}
.arrow-direction{
	font-size: 22px;
	line-height: 150%;
	color: #333;
	padding-top: 10px;
	z-index: 199999;
	background: #fff;
	display:block;
	position: relative;
}
.arrow-direction p{
	font-size: 14px;
	margin: 0px;
	padding-top: 5px;
}
.arrow-direction p strong{
	font-size: 22px;
}
.arrow-direction img{
	vertical-align: middle;
	padding-left: 10px;
}
.arrow-bottom{
	color: #606060;
	font-size: 16px;
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		-webkit-transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-20px);
	}
	60% {
		-webkit-transform: translateY(-5px);
	}
}
`;

export let init = async function (Util) {
	if (!navigator.userAgent.includes("Mac") && navigator.userAgent.includes("Chrome")) {
		let firstRdr = Util.getSetting("firstRdr");
		if (firstRdr === "true"){
			var hidePrompt = function(){
				document.querySelector("#arrow-modal").innerHTML = "";
				document.body.onclick = null;
			}

			let styles = document.createTextNode(promptCSS);
			let promptStyles = document.createElement("STYLE");
			promptStyles.appendChild(styles);
			document.querySelector("body").appendChild(promptStyles);

			var promptDiv = document.createElement('div');
			promptDiv.innerHTML = promptHTML;
			document.querySelector("body").appendChild(promptDiv);

			document.body.onclick = function(){
				hidePrompt();
			}
			setTimeout(hidePrompt, 5000);

			Util.setSetting("firstRdr", false);
		}
	}
}
